//
// Board
//

.board {
	position: relative;
	height: 100%;
	flex-wrap: nowrap;
	overflow-x: scroll;
}

.board-group-container {
	  width: 18rem;
}

.board-group {
	width: 27rem;
}

.bg-green1{
	background-color: #236b06;
}
.bg-green2{
	 
	//background-image: url('../../assets/img/loginpage.png');
	background-position: center; /* Center the image */
	background-repeat: no-repeat; /* Do not repeat the image */
	background-size: cover; /* Resize the background image to cover the entire container */
}
.bg-green3{
	 
	//background-image: url('../../assets/img/loginpage.png');
	background-position: center; /* Center the image */
	background-repeat: no-repeat; /* Do not repeat the image */
	background-size: cover; /* Resize the background image to cover the entire container */
}
